// /* eslint-disable @typescript-eslint/no-unused-vars */
// /* eslint-disable no-console */
// /* eslint-disable no-param-reassign */
// import { createSlice } from "@reduxjs/toolkit";
// import { useAppDispatch } from "redux/store";

// export interface IConnectorReducer {
//   selectedConnectorObjects: any[];
// }

// const initialState: IConnectorReducer = {
//   selectedConnectorObjects: [],
// };

// interface IPayload {
//   connectorObject?: any;
// }

// interface IAction {
//   payload: IPayload;
//   type: string;
// }

// const connectorSlice = createSlice({
//   name: "connectors",
//   initialState,
//   reducers: {
//     addSelectedConnectorObject: (state, action: IAction) => {
//       if (action?.payload?.connectorObject) {
//         state.selectedConnectorObjects = [
//           ...state.selectedConnectorObjects,
//           action.payload.connectorObject,
//         ];
//       }
//     },
//   },
// });

// export const { addSelectedConnectorObject } = connectorSlice.actions;

// export const useConnectorActions = () => {
//   const dispatch = useAppDispatch();
//   return {
//     addSelectedConnectorObject: (payload: IPayload) =>
//       dispatch(addSelectedConnectorObject(payload)),
//   };
// };

// const { reducer } = connectorSlice;

// export default reducer;

import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface IConnectorObject {
  id: string;
  connecterId: string;
  chargerPointId: string;
  power: string;
  cost: string;
  connector: {
    id: string;
    name: string;
    image: string;
  };
}

export interface IConnectorReducer {
  selectedConnectorObjects: IConnectorObject[];
}

const initialState: IConnectorReducer = {
  selectedConnectorObjects: [],
};

const connectorSlice = createSlice({
  name: "connectors",
  initialState,
  reducers: {
    addSelectedConnectorObject: (state, action) => {
      state.selectedConnectorObjects = [action.payload.connectorObject];
    },
  },
});

export const { addSelectedConnectorObject } = connectorSlice.actions;

export const useConnectorActions = () => {
  const dispatch = useAppDispatch();
  return {
    addSelectedConnectorObject: (payload: {
      connectorObject: IConnectorObject;
    }) => dispatch(addSelectedConnectorObject(payload)),
  };
};

export default connectorSlice.reducer;
