import { Route, Routes } from "react-router-dom";
import * as AuthLoadable from "./ScreenContainer/AuthLoad";
import AppRoutes from "./appRoutes";

function Application() {
  return (
    <Routes>
      {/* <Route element={<AuthLoadable.QRScan />} path={AppRoutes.SCANQR} /> */}
      <Route
        element={<AuthLoadable.StationInfo />}
        path={AppRoutes.STATIONINFO}
      />
      <Route
        element={<AuthLoadable.StationInfo />}
        path={`${AppRoutes.STATIONINFO}/:id`}
      />
      <Route
        element={<AuthLoadable.UserDetailForm />}
        path={`${AppRoutes.USERDETAILFORM}/:cpId/:connectorId`}
      />
      <Route
        element={<AuthLoadable.ConnectCar />}
        // path={AppRoutes.CONNECTCAR}
        path={`${AppRoutes.CONNECTCAR}/:cpId/:userId/:value/:connectorId/:paymentId`}
      />
      <Route
        element={<AuthLoadable.ChargingStart />}
        path={AppRoutes.CHARGINGSTART}
      />
      <Route
        element={<AuthLoadable.ChargingStation />}
        path={AppRoutes.CHARGINGSTATION}
      />
      <Route
        element={<AuthLoadable.Invoice />}
        path={`${AppRoutes.INVOICE}/:payId/:amountRefund`}
      />
    </Routes>
  );
}

export default Application;
